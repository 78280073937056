<template>
      <div class="current1" >
          <!---->
          <div class="current-div1 teb_header" style="width: 100%;" > 

                <div style="display:flex;width:100%;justify-content: space-between;">
                      <h3 style="padding:20px">{{tableData.cname}}</h3>

                      <el-button style="margin-right:10px" :size="tabsize" class="button" @click="editDeta" type="success"><i class="el-icon-edit"></i>编辑</el-button>

                </div>

                
                <div id="top-a" style="width:80%;margin-bottom: 20px;">
                  <div class="xq">
                      <label>用户级别: &nbsp;{{tableData.mlvl}}</label>
                      <label>用户种类: &nbsp;{{tableData.mtype}}</label>
                      <label>用户简称: &nbsp;{{tableData.jname}}</label>
                      <!-- <label>网站简称: &nbsp;{{tableData.wname}}</label> -->
                      <label>登录账号: &nbsp;{{tableData.loginname}}</label>
                      <label>微信客服: &nbsp;{{tableData.wxkf}}</label>
                      <label style="white-space: nowrap;">详细地址: &nbsp;{{tableData.add1}},{{tableData.add2}},{{tableData.add3}},{{tableData.address}}</label>
                  </div>

                   <div class="xq">
                      <label>管理员: &nbsp;{{tableData.gname}}</label>
                      <label>用户编号: &nbsp;{{tableData.code}}</label>
                      <label>使用次数: &nbsp;{{tableData.usenum}}</label>
                      <label>联系电话: &nbsp;{{tableData.phone}}</label>
                      <label>服务商代码: &nbsp;{{tableData.code1}}</label>
                  </div>

              </div>

              <div id="top-a" style="width:80%;margin-bottom: 20px;margin-left: 20px;margin-top: -12px;">
                    <label>用户简介: &nbsp;{{tableData.content}}</label>
              </div>
          </div>

          <div class="current-div1 teb_header" style="width: 100%;" > 
              <h3 style="padding:20px">用户数</h3>
              
              <div class="usernum">
                  <span>
                    <p>全部</p>
                    <p>{{sumData.nums}}</p>
                  </span>
                  <span>
                    <p>未成年人</p>
                    <p>{{sumData.num1}}</p>
                  </span>
                  <span>
                    <p>档案数</p>
                    <p>{{sumData.num2}}</p>
                  </span>
                  <span>
                    <p>月度新增</p>
                    <p>{{sumData.num3}}</p>
                  </span>
              </div>
          </div>

          <div class="current-div1 teb_header" style="width: 100%;" > 
              <h3 style="padding:20px 20px 0 20px">专属渠道地址</h3>
              <span style="margin: 10px 20px 20px 20px;" target="_blank" :href="tableData.code">{{tableData.url+tableData.code}}</span>
          </div>

          <div class="current-div1 teb_header" style="width: 100%;" > 
              <h3 style="padding:20px">公众号二维码</h3>
              <div style='margin-left: 20px;'>
                 <el-image style="width:200px;height:200px;margin-left: 10px;" :src="tableData.qrcode" :fit="fit"></el-image>
              </div>
          </div>
          
      </div>
</template>

<script>
    export default {
      created(){
          
          this.$api.detailes.userReport({
              
          }).then(res1 => {
              this.sumData = res1.obj
          }).catch((error) => {
             console.log(error);
          })

          
          this.$api.detailes.getById({

          }).then(res => {
            if(res.mlvl == 1){
                res.mlvl = "A"
            }else if(res.mlvl == 2){
                res.mlvl = "B"
            }else if(res.mlvl == 3){
                res.mlvl = "C"
            }else if(res.mlvl == 4){
                res.mlvl = "D"
            }else if(res.mlvl == 5){
                res.mlvl = "E"
            }else{
                res.mlvl = "未知"
            }

            if(res.mtype == 1){
                res.mtype = "旗舰店"
            }else if(res.mtype == 2){
                res.mtype = "体验店"
            }else if(res.mtype == 3){
                res.mtype = "医院"
            }else if(res.mtype == 4){
                res.mtype = "视光中心"
            }else if(res.mtype == 5){
                res.mtype = "视保店"
            }else if(res.mtype == 6){
                res.mtype = "智能体验馆"
            }else{
                res.mtype = "未知"
            }
        
            this.tableData = res;
            // this.tableData.code1 = this.tableData.code1.slice(0,6) + ' ' + this.tableData.code1.slice(6) 
            this.$api.detailes.getAreaList({
               
            }).then(res1 => {
                res1.obj.options.forEach(element => {

                        if(element.value == this.tableData.add1){
                            this.tableData.add1 = element.label

                            element.children.forEach(element1 => {

                                if(element1.value == this.tableData.add2){
                                    this.tableData.add2 = element1.label

                                    element1.children.forEach(element2 => {

                                        if(element2.value == this.tableData.add3){
                                            this.tableData.add3 = element2.label
                                        }
                                });
                        }
                            });
                        }
                });
         
            })//获取位置信息
          }).catch((error) => {
              console.log(error);
          })
      },
      data(){
        return {
          fit:"contain",
          personnelid:'',
          img: "",
          input:'',
          inputpersonid:'',//暂时为死数据
          tableData:[],
          sumData:[],
          address:[]
        }
      },
      // 监听尺寸按钮变化 改变表格大小
      computed: {
          tabsize() {
              
              return this.$store.state.tabsize
          }
      },
      methods: {
          editDeta(){
              this.$router.push({
                path: "/detaEdit",
                query: { },
            });
          }
      }
      
      }
</script>

<style scoped>

.current1{
    position: relative;
    width: 98%;
    height: 98%;
    overflow: auto;
    margin: 1%;
  }
  .current-div1{
    height: auto;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    color: #666666;
    justify-content: center;
  }
 

  #top-a{
      display: flex;
      justify-content: space-between;
  }

  .xq{
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        width:300px;
   }
   .xq label{
       margin-top:10px;
       white-space: nowrap;
       /* overflow: hidden;
       text-overflow: ellipsis;   */
    }

   .usernum{
        display: flex;
        justify-content: space-around;
        margin-left: 20px;
   }

   .usernum span{
        background-color: #409eff;
        display: flex;
        flex-direction: column;
        width: 15%;
        height: 60px;
        color: #fff;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        margin-bottom: 20px;
   }

   .button{
       height: 28px;
       display: flex;
       margin-top: 17px;
       margin-left: 30px;
   }

</style>
